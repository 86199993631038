import React from 'react'
import '../components/Styles/gridlex.scss'
import '../components/Styles/global.css' 

import NovaLayout from '../components/NovaLayout'
import { IndexPage } from '../components/IndexPage'

const Index = () => {
  return (
    <NovaLayout isLight title="Home | Nova Digital Agency"  description="A digital agency designed for growth-driven mortgage businesses">
      <IndexPage />
    </NovaLayout>
  )
}

export default Index

import React, { Component } from 'react'
import './styles.css'

class Service extends Component {
  constructor(props) {
    super(props)
    this.state = {
      descriptionVisible: false,
    }
    this.handleClick = this.handleClick.bind(this);
  }

  
  hidedescriptionOnClick(e) {
    if (this.state.descriptionVisible) {
      const componentBoundaries = document
        .querySelector('.services-image-container')
        .getBoundingClientRect()
      const outsidedescription =
      e.x < componentBoundaries.left ||
        e.x > componentBoundaries.right ||
        e.y < componentBoundaries.top ||
        e.y > componentBoundaries.bottom
        
      if (outsidedescription) {
        this.toggleDescription()
      }
    }
  }
  
  toggleDescription() {
    this.setState({ descriptionVisible: !this.state.descriptionVisible })
  }
  
  handleClick() {
    this.toggleDescription()
 }
  render() {
    
    return (
      <div
        onClick={this.handleClick}
        className="services-image-container"
      >
        <img className="services-image-container-image" src={this.props.stockImage} alt={`${this.props.name} stockImage`}/>
        <div className="services-image-cover"/>
        <div className="services-text-wrapper">
          <img className="service-svg" src={this.props.svg}/>
          <h4 className="service-text">{this.props.serviceName}</h4>
        </div>
        <div
          className={
            this.state.descriptionVisible
              ? 'photo-container-info-text-container info-text-revealed'
              : 'photo-container-info-text-container info-text-revealed info-text-hidden'
          }
        >
          <div>
            <h5>{this.props.serviceName}</h5>
            <p className="text-medium mt-1">
              {this.props.description}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Service

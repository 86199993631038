import React from 'react'

import classNames from 'classnames'
// import { Image, IndexPageIntro, IndexPageReview } from '../../../../../novamortgage/src/types/pages'
import { convertTitleToSlug } from '../../utils/convertTitleToSlug'
import { backgroundImageStyle, getImageSrc } from '../../utils/image.js'
import StepsArray from '../../utils/stepsArray.js'

import Button from '../Button'
import GtmClick from '../GtmClick'
import Link from '../Link'
import './styles.css'

import HeroColorBlock from "../../images/HeroColorBlock.svg"
import PurpleGradientBlock from "../../images/PurpleGradientBlock.svg"
import HomePageHero from "../../images/Hero.svg"
import Services from "../Services"
import { CTASection } from "../CTASection"


export const IndexPage = () => {
  return (
    <div>
      <HeroSection
        heading="Strategy. Technology. Acceleration."
        subheading="Built exclusively for growth-driven mortgage businesses."
        isHeroWhite
        image={false}
        home
      />

      <div className="layout-body">
        <IntroSection 
          steps={StepsArray}
          heading="Our Recipe For Success"
        />
      </div>
      <Services/>
      <CTASection 
        heading="Get a Free Assessment Now"
        prompt="Ready to grow?"
        buttonText="Get Started"
        gtaText="gtm-home-cta-section-get-started"
      />
    </div>
  )
}


export const HeroSection = (props) => {
  const className = classNames('index-page-hero mb-6 xs-mb-4 xs-center', {
    'index-page-hero-white': props.isHeroWhite,
  })
  return (
    <div
      className={className}
      style={props.image && !props.isHeroWhite ? backgroundImageStyle(props.image) : {}}
    >
      {props.home && 
        <div>
          <div className="home-nav-block-wrapper">
            <img className="home-nav-block" src={PurpleGradientBlock}/>
          </div>
          <div className="home-nav-curved-block-wrapper">
            <img className="home-nav-curved-block" src={HeroColorBlock}/>
          </div>
        </div>
      }
      <div className="layout-body hero-body">
        <div className="grid">
          <div className="col">
            <div className='hero-teaser-text'>
              Your Virtual Marketing Team
            </div>
            <div>
              <h2 className="mt-0-5 mb-1 xs-mb-0-5">{props.heading}</h2>
            </div>
            <div>
              <h5 className="mt-1">{props.subheading}</h5>
            </div>
            {props.partnerLogo && (
              <div className="mt-1-5">
                <img src={getImageSrc(props.partnerLogo)} />
              </div>
            )}

            <div className="mt-2 mb-0-5">
              <Link to="/contact">
                <GtmClick category="click" id={`gtm-home-hero-cta`}>
                  <Button theme="orange">
                    Get started
                  </Button>
                </GtmClick>
              </Link>
            </div>
          </div>
          <img className="hero-image" src={HomePageHero}/>
        </div>
        {/* {props.isHeroWhite && (
          <div className="grid-center mt-4">
            <div className="hr" />
          </div>
        )} */}
      </div>
    </div>
  )
}

const IntroSection = (intro) => (
  <div className="mb-5">
    <h2 className="text-center">{intro.heading}</h2>
    <h4 className="text-center mb-5 xs-mb-4">{intro.subheading}</h4>
    <div className="index-page-steps">
      {intro.steps.map(step => (
        <div key={step.tag} className="grid-center index-page-step mb-3">
          <div className="col-4_sm-5_xs-7-middle index-page-step-image">
            <img src={step.image} />
          </div>
          <div className="col-6_sm-7_xs-12 xs-center">
            <div className="text-small mb-0-5 xs-mt-1">
              <span className="tag-purple">{step.tag}</span>
            </div>
            <h3 className="mb-0-5">{step.title}</h3>
            <p className="text-large mb-2">{step.text}</p>
            <div>
              <Link to={step.link}>
                <GtmClick
                  category="click"
                  id={`gtm-home-hero-step-${convertTitleToSlug(step.button)}`}
                >
                  <Button>{step.button}</Button>
                </GtmClick>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
    <div className="grid-center mt-4">
      <div className="hr" />
    </div>
  </div>
)



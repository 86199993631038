import React, { Component } from 'react'
import './styles.css'
import Service from '../Service'

import ServicesArray from '../../utils/servicesArray.js'

import Button from '../Button'
import GtmClick from '../GtmClick'
import Link from '../Link'



class Services extends Component {
  // componentWillMount() {
  //   butter.page.retrieve('*', 'services-members').then(resp => {
  //     this.setState({
  //       content: sortBy(resp.data.data.fields.services_member, [
  //         u => Number(u.position),
  //       ]),
  //     })
  //   })
  // }

  render() {
    return (
        <div className="services" id="services">
          <h2 className="text-center">Services</h2>
          <div className="services-image-grid">
            {ServicesArray.map(service => (
              <Service
                key={service.name}
                stockImage={service.stockImage}
                serviceName={service.serviceName}
                description={service.description}
                svg={service.svg}
              />
            ))}
        </div>
        <div className="layout-body">
          <div className="mt-2 mb-0-5">
            <Link to="/services">
              <GtmClick category="click" id={`gtm-home-learn-more-about-services-cta`}>
                <Button >
                  Learn more about our services
                </Button>
              </GtmClick>
            </Link>
          </div>
          <div className="grid-center mt-4">
            <div className="hr" />
          </div>
        </div>
      </div>
    )
  }
}

export default Services
